<template>
  <div>
    <div>
      <div class="container text-center py-4">
        <h2>{{ $t("products") }}</h2>
      </div>
    </div>
    <div class="my-4">
      <tabs @click:tab="CLICK_TAB" :fields="Types"></tabs>
    </div>
    <Products
      class="stripped-light"
      v-for="(type, i) in Types"
      :key="i"
      :id="type.id"
      :search="search"
      :newProductPath="type.newProductPath"
      :view-all-url="type.domain"
      @search:product="(s) => SET_SEARCH_PRODUCT(s, type)"
      :limit="4"
      :product-type="type.type"
      :productList="SET_RETURN_PRODUCT_LIST(type.computed)"
      has-info
      :title="type.title"
    >
    </Products>
    <!--Featured Videos end-->
  </div>
</template>

<script>
import Types from "../Type/Types";
import Products from "../List";
import Tabs from "../Product/Tabs.vue";
export default {
  mixins: [Types],
  components: { Products, Tabs },
  data() {
    return {
      categoryId: -1,
      search: "",
      category: { id: -1 },
      image: {
        uri: "",
        file: [],
      },
    };
  },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getCourses() {
      return this.$store.state.Courses.courses.filter((c, i) => i < 4);
    },
    getBooks() {
      return this.$store.state.Books.books.filter((c, i) => i < 4);
    },
    getEvents() {
      return this.$store.state.Events.events.filter((c, i) => i < 4);
    },
    getSeances() {
      return this.$store.state.Seances.seances.filter((c, i) => i < 4);
    },
  },
  methods: {
    CLICK_TAB(tab) {
      document.getElementById(tab.id).scrollIntoView({ behavior: "smooth" });
    },
    SET_SEARCH_PRODUCT(search, req) {
      this.search = search;
      this.$store.dispatch(req.dispatchPath, {
        limit: 4,
        type: req,
        search,
        page: 1,
      });
    },
    SET_RETURN_PRODUCT_LIST(key) {
      return this[key];
    },
    async SET_IMAGE_WHICH_IS_CHANGED(e) {
      this.image = e;
      const fd = new FormData();
      fd.append("Image", e.file);
      fd.append("Name", this.category.name);
      fd.append("Description", this.category.description);
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: { status: false },
      });
      const response = await this.$store.dispatch("SEND_TO_UPDATE_CATEGORY", {
        category: {
          id: this.category.id,
          name: this.category.name,
          description: this.category.description,
          imagePath: e.uri,
        },
        fd,
      });
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: response,
      });
    },
  },
  created() {
    this.$store.dispatch("GET_COURSES", {
      limit: 4,
      type: this.Types.find((type) => type.type === 2),
    });
    this.$store.dispatch("GET_BOOKS", {
      limit: 4,
      type: this.Types.find((type) => type.type === 4),
    });
    this.$store.dispatch("GET_EVENTS", {
      limit: 4,
      type: this.Types.find((type) => type.type === 1),
    });
    this.$store.dispatch("GET_SEANCES", {
      limit: 4,
      type: this.Types.find((type) => type.type === 3),
    });
  },
};
</script>
