<template>
  <Products :key="keyComp"></Products>
</template>

<script>
import Products from "../components/Products";
import Reload from "../mixins/Reload";
export default {
  mixins: [Reload],
  components: { Products },
};
</script>
